import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
//import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const PageCollectionAuthors = ({
  data: { posts, collectionInfo },
  ...props
}) => (
  <Layout {...props}>
    {/*<Seo title={collectionInfo.name} description={collectionInfo.description} />*/}
    {/*<GatsbySeo
        title='title'
        description='description'
        openGraph={{
          title: 'title',
          description: 'description',
          url: 'https://www.ownahome.ng/',
          images: 
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'find out about us'
            },
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}
      />*/}

    <Divider />
    <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle header='Published by Author' totalCount={posts.totalCount} />
    </Stack>
    <Divider />
    <Stack>
      <Main>
        <AuthorExpanded author={collectionInfo} />
        <Divider />
        {posts.nodes && (
          <CardList
            nodes={posts.nodes}
            variant={['horizontal-md', 'vertical']}
            columns={[1, 2, 3, 3]}
          />
        )}
      </Main>
    </Stack>
    <Divider />
    <PreFooter>
      <Pagination {...posts.pageInfo} {...collectionInfo} />
    </PreFooter>
  </Layout>
)

export default PageCollectionAuthors
